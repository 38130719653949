import React, { useEffect, useState } from "react";
import { useFilterContext } from "../helpers/FilterContext";

function FilterInputBoolean({ filter, filtersContent }) {
  const { state, dispatch } = useFilterContext();
  const filterName = filter[0];
  const filterId = filter[1][3];

  const [boolean, setBoolean] = useState(false);
  useEffect(() => {
    const isFilterApplied = state.urlTestParams.filters?.[filterId] !== undefined;
    setBoolean(isFilterApplied);
  }, [state.urlTestParams.filters?.[filterId]]);

  function handleCheckClick() {
    const newValue = !boolean;
    setBoolean(newValue);

    if (newValue) {
      dispatch({ type: "UPDATE_FILTERS", payload: { filterId: filterId, values: filter[1][1] } });
    } else {
      dispatch({ type: "REMOVE_FILTER", payload: filterId });
    }
  }

  return (
    <div className="flex items-center py-4 flex-col">
      <div className="w-full flex flex-row flex-wrap gap-x-4 mt-4">
        <div className="w-[calc(50%-10px)]">
          <span
            className={`w-[15px] h-[15px] border border-primary inline-block mr-2 rounded-full checkbox-filter ${
              boolean ? "checked" : ""
            }`}
            onClick={handleCheckClick}
          ></span>
          <input type="checkbox" className="hidden" checked={boolean} readOnly />
          <label htmlFor="">{filter[1][2]}</label>
        </div>
      </div>
    </div>
  );
}

export default FilterInputBoolean;
