import React, { useEffect, useState } from "react";
import { is, DataEncoder } from "../../utils/common";
import RenderCategories from "./RenderCategories";
import { useFilterContext } from "../../helpers/FilterContext";

function FilterCategories({ filter, filtersContent, fetchedFilters }) {
  const [allCategories, setAllCategories] = useState({});
  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
  const [categoriesSelected, setCategoriesSelected] = useState([]);
  const { state, dispatch } = useFilterContext();

  useEffect(() => {
    if (fetchedFilters && fetchedFilters.categories_ids) {
      const selectedCategories = Object.keys(fetchedFilters.categories_ids).map(
        (key) => fetchedFilters.categories_ids[key]
      );
      setCategoriesSelected(selectedCategories);
    }
  }, [fetchedFilters]);

  const encoder = new DataEncoder();
  const fetchAllCategories = async () => {
    const params = {
      with: ["categories.categories.categories"],
      filters: {
        level: 1,
      },
    };
    try {
      const response = await fetch(
        `${API_BASE_URL}/categories?${encoder.encode(params)}`
      );
      if (!response.ok) {
        throw new Error("Error al cargar las categorías");
      }
      const responseData = await response.json();
      setAllCategories(responseData);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  useEffect(() => {
    fetchAllCategories();
  }, []);

  function deleteCategorieId() {
    dispatch({ type: "REMOVE_FILTER", payload: "categories_ids" });
  }

  function handleCheckBoxClick(categoryId) {
    // Verificamos si la categoría ya está seleccionada
    const isAlreadySelected = categoriesSelected.some(
      (cat) => cat.value === categoryId
    );

    let updatedCategories;
    if (isAlreadySelected) {
      // Si ya estaba seleccionada, la quitamos
      updatedCategories = categoriesSelected.filter(
        (cat) => cat.value !== categoryId
      );
    } else {
      // Si no estaba seleccionada, la añadimos
      const category = allCategories.data.find((cat) => cat.id === categoryId);
      if (category) {
        updatedCategories = [...categoriesSelected, { value: categoryId, text: category.name }];
      } else {
        console.warn(`⚠️ Categoría con ID ${categoryId} no encontrada.`);
        return;
      }
    }

    // Actualizamos el estado
    setCategoriesSelected(updatedCategories);

    // Disparamos la acción en el contexto
    const selectedIds = updatedCategories.map((cat) => cat.value);
    dispatch({ type: "UPDATE_FILTERS", payload: { filterId: "categories_ids", values: selectedIds } });
  }

  return (
    <div className="flex flex-col items-center py-4">
      <RenderCategories
        categories={allCategories.data}
        fetchedFilters={fetchedFilters}
        onCategoryClick={handleCheckBoxClick}
      />
    </div>
  );
}

export default FilterCategories;
