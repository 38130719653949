import React from "react";
import Wizard from "./Wizard";

const ModalWizard = ({ isOpen, onClose }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50 px-4 sm:px-0">
    <div className="w-full max-w-sm md:max-w-4xl md:w-auto relative bg-white rounded-lg shadow-lg p-4 h-auto max-h-[90vh] overflow-y-auto">
      <button 
        onClick={onClose} 
        className="absolute top-2 right-2 text-gray-600 hover:text-gray-800"
      >
        ✕
      </button>
      <div className="py-8">
        <Wizard />
      </div>
    </div>
  </div>
  

  );
};

export default ModalWizard;
