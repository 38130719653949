import React, { useState, useEffect } from "react";
import { useFilterContext } from "../helpers/FilterContext";

function FilterInputList({ filter, filtersContent }) {
  const { state, dispatch } = useFilterContext();
  let list = [];

  if (filter[0] === "propulsion") {
    list = filtersContent.values.propulsion;
  } else if (filter[0] === "use_type") {
    list = filtersContent.values.use_type;
  }

  const [selectedItem, setSelectedItem] = useState(null);

  useEffect(() => {
    const selectedValues = state.urlTestParams.filters?.[filter[0]];

    if (filter[0] === "use_type") {
      setSelectedItem(typeof selectedValues === "string" ? selectedValues : null);
    } else {
      setSelectedItem(Array.isArray(selectedValues) ? selectedValues[selectedValues.length - 1] : null);
    }
  }, [state.urlTestParams.filters?.[filter[0]]]);

  function handleListClick(item) {
    setSelectedItem(item); 

    if (filter[0] === "propulsion") {
      dispatch({
        type: "UPDATE_FILTERS",
        payload: { filterId: "propulsion", values: [9, item] }, 
      });
    } else if (filter[0] === "use_type") {
      dispatch({
        type: "UPDATE_FILTERS",
        payload: { filterId: "use_type", values: item },
      });
    }
  }

  return (
    <div className="flex items-center py-4 flex-col">
      <div className="w-full flex flex-row flex-wrap gap-x-4 mt-4">
        {list &&
          list.map((item) => (
            <div key={item} className="w-[100%]">
              <span
                className={`w-[15px] h-[15px] border border-primary inline-block mr-2 rounded-full checkbox-filter ${
                  selectedItem === item ? "checked" : ""
                }`}
                onClick={() => handleListClick(item)}
              ></span>
              <input
                type="checkbox"
                className="hidden"
                checked={selectedItem === item}
                readOnly
              />
              <label className="text-sm">{item}</label>
            </div>
          ))}
      </div>
    </div>
  );
}

export default FilterInputList;
