import { CarrouselCategories } from "../components/CarrouselCategories";

import ImageWithText from "../components/sections/ImageWithText";
import ImageWithTextImage from "../../src/assets/images/Advantages/hero-advantages.webp";

import AdvantageIcon1 from "../../src/assets/images/Advantages/icon-advantages-1.svg";
import AdvantageIcon2 from "../../src/assets/images/Advantages/icon-advantages-2.svg";
import AdvantageIcon3 from "../../src/assets/images/Advantages/icon-advantages-3.svg";
import AdvantageIcon4 from "../../src/assets/images/Advantages/icon-advantages-4.svg";
import AdvantageIcon5 from "../../src/assets/images/Advantages/icon-advantages-5.svg";
import AdvantageIcon6 from "../../src/assets/images/Advantages/icon-advantages-6.svg";
import AdvantageIcon7 from "../../src/assets/images/Advantages/icon-advantages-7.svg";
import AdvantageIcon8 from "../../src/assets/images/Advantages/icon-advantages-8.svg";
import AdvantageIcon9 from "../../src/assets/images/Advantages/icon-advantages-9.svg";
import AdvantageIcon10 from "../../src/assets/images/Advantages/icon-advantages-10.svg";

import Advantage1 from "../../src/assets/images/Advantages/advantage-1.webp";
import Advantage2 from "../../src/assets/images/Advantages/advantage-2.webp";
import Advantage3 from "../../src/assets/images/Advantages/advantage-3.webp";

const ImageWithTextVariables = {
  title: "motivos para alquilar maquinaria y herramientas profesionales",
  highlightedText: "y herramientas profesionales",
  highlightClass: "text-primary block",
  subtitle: "",
  buttonText: "Seguir leyendo",
  href: "#section1",
  image: ImageWithTextImage,
  alt: "maquinaria profesional amarilla con componentes industriales",
  h1AdditionalClasses: "lg:!px-32",
  useH1: true,
};

function Advantages() {
  return (
    <>
      <ImageWithText variables={ImageWithTextVariables} />
      <section id="section1" className="">
        <div className="text-center px-4 lg:px-20 py-14 md:py-24 text-black h2 space-y-1">
          <h2 className="h1 lowercase pb-12">
            <span className="text-primary">Ventajas </span>
            de alquilar
          </h2>
          <div className="w-full flex justify-center">
            <div className="grid grid-cols-2 md:grid-cols-5 gap-6">
              <div className="flex items-center gap-4">
                <img
                  src={AdvantageIcon1}
                  className="w-12 h-auto object-cover"
                  alt="Reducción Costes Fijos"
                />
                <h3 className="body font-bold text-start">
                  Reducción Costes Fijos
                </h3>
              </div>
              <div className="flex items-center gap-4">
                <img
                  src={AdvantageIcon2}
                  className="w-12 h-auto object-cover"
                  alt="Minimizar Riesgos Financieros"
                />
                <h3 className="body font-bold text-start">
                  Minimizar Riesgos Financieros
                </h3>
              </div>
              <div className="flex items-center gap-4">
                <img
                  src={AdvantageIcon3}
                  className="w-12 h-auto object-cover"
                  alt="Ventajas Fiscales"
                />
                <h3 className="body font-bold text-start">Ventajas Fiscales</h3>
              </div>
              <div className="flex items-center gap-4">
                <img
                  src={AdvantageIcon4}
                  className="w-12 h-auto object-cover"
                  alt="Disponibilidad inmediata"
                />
                <h3 className="body font-bold text-start">
                  Disponibilidad inmediata
                </h3>
              </div>
              <div className="flex items-center gap-4">
                <img
                  src={AdvantageIcon5}
                  className="w-12 h-auto object-cover"
                  alt="Asesoramiento por nuestra parte"
                />
                <h3 className="body font-bold text-start">
                  Asesoramiento por nuestra parte
                </h3>
              </div>
              <div className="flex items-center gap-4">
                <img
                  src={AdvantageIcon6}
                  className="w-12 h-auto object-cover"
                  alt="Posibilidad de contar con equipos de última tecnología"
                />
                <h3 className="body font-bold text-start">
                  Posibilidad de contar con equipos de última tecnología
                </h3>
              </div>
              <div className="flex items-center gap-4">
                <img
                  src={AdvantageIcon7}
                  className="w-12 h-auto object-cover"
                  alt="Control presupuestario en obras"
                />
                <h3 className="body font-bold text-start">
                  Control presupuestario en obras
                </h3>
              </div>
              <div className="flex items-center gap-4">
                <img
                  src={AdvantageIcon8}
                  className="w-12 h-auto object-cover"
                  alt="Mejor eficiencia"
                />
                <h3 className="body font-bold text-start">Mejor eficiencia</h3>
              </div>
              <div className="flex items-center gap-4">
                <img
                  src={AdvantageIcon9}
                  className="w-12 h-auto object-cover"
                  alt="Absorción de Picos de trabajo"
                />
                <h3 className="body font-bold text-start">
                  Absorción de Picos de trabajo
                </h3>
              </div>
              <div className="flex items-center gap-4">
                <img
                  src={AdvantageIcon10}
                  className="w-12 h-auto object-cover"
                  alt="Máquinas revisadas por expertos"
                />
                <h3 className="body font-bold text-start">
                  Máquinas revisadas por expertos
                </h3>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="text-start py-14 bg-white">
        <div className="container m-auto text-black h2 px-4 lg:px-20 space-y-14 md:space-y-24">
          {/* Advantage 1 */}
          <div className="w-full md:flex items-center">
            <div className="h-full w-full md:w-7/12">
              <h2 className="pt-4 pb-6 h1 lowercase text-black">
                descubre la
                <span className="text-primary"> solución ideal </span>
                de equipamiento para tu proyecto
              </h2>
              <p className="body text-dark leading-5 pr-0 lg:pr-12">
                Con nuestro servicio integral de alquiler te brindamos
                soluciones flexibles y a medida para tus necesidades de
                equipamiento. En el corazón de la innovación en múltiples
                industrias ofrecemos un enfoque personalizado para la provisión
                de maquinaria.
              </p>
            </div>
            <img
              src={Advantage1}
              className="mt-4 md:mt-0 rounded-3xl h-full w-full md:w-5/12 object-contain"
              alt="Excavadoras amarillas aparcadas en fila"
            />
          </div>

          {/* Advantage 2 */}
          <div className="w-full md:flex items-center gap-12">
            <div className="h-full w-full md:w-7/12 order-2 md:order-1">
              <h2 className="pt-4 pb-6 h1 lowercase text-black">
                <span className="text-primary">Alquiler integrado </span>
                en tu cadena de suministro
              </h2>
              <p className="body text-dark leading-5">
                Nuestro modelo de alquiler se adapta perfectamente a tu cadena
                de suministro, proporcionando desde equipos de última generación
                hasta opciones de renting flexibles, ajustadas a cada proyecto.
              </p>
            </div>
            <img
              src={Advantage2}
              className="mt-4 md:mt-0 rounded-3xl h-full w-full md:w-5/12 object-contain"
              alt="Plataformas elevadoras articuladas rojas extendidas hacia el cielo, utilizadas en trabajos en altura"
            />
          </div>

          {/* Advantage 3 */}
          <div className="w-full md:flex items-center gap-12">
            <div className="h-full w-full md:w-7/12">
              <h2 className="pt-4 pb-6 lowercase h1 text-black">
                Adaptable a cada
                <span className="text-primary lowercase"> necesidad </span>
              </h2>
              <p className="body text-dark leading-5">
                Sea cual sea tu industria o el tamaño de tu proyecto tenemos las
                opciones de alquiler y renting que buscas. Contáctanos para
                saber cómo podemos ayudarte a avanzar en tus proyectos con
                eficiencia y costos optimizados.
              </p>
            </div>
            <img
              src={Advantage3}
              className="mt-4 md:mt-0 rounded-3xl h-full w-full md:w-5/12 object-contain"
              alt="Adaptable a cada necesidad"
            />
          </div>
        </div>
      </section>
      <section className="py-14 md:py-24">
        <div className="">
          <div className="container m-auto md:text-center px-4 lg:px-20 text-black h2 space-y-1">
            <h2 className="h1 pb-9 lowercase">
              Nuestro
              <span className="text-primary"> catálogo</span>
            </h2>
            <div className="body text-dark leading-5 px-0 md:px-12 lg:px-24">
              <p className="pb-4">
                Te ofrecemos un catálogo diversificado de equipos y herramientas
                de vanguardia perfectamente adaptado a tus necesidades. Con
                nuestra atención exclusiva y personalizada garantizamos que la
                experiencia del alquiler sea totalmente satisfactoria e
                inigualable.
              </p>
              <p className="pb-4">
                En cuanto a nuestra oferta respecto a la diversidad y
                adaptabilidad trabajamos para que nuestro catálogo sea un
                reflejo de la evolución constante que marca las exigencias del
                mercado europeo. Gracias a esto, con estrategias y modelos
                operativos innovadores, estamos comprometidos a proporcionarte
                las soluciones más eficaces y actualizadas para tus proyectos.
              </p>
              <p className="pb-4">
                Por todo esto, te animamos a que explores nuestro extenso
                catálogo y descubras cómo nuestros equipos pueden impulsar tu
                éxito. Contacta con nosotros para una consulta personalizada y
                encuentra las herramientas que necesitas para triunfar en tus
                proyectos.
              </p>
            </div>
          </div>
        </div>
        <CarrouselCategories
          title="Descubre todas las categorías"
          highlightedText="categorías"
        />
      </section>
    </>
  );
}

export default Advantages;
