import React, { createContext, useContext, useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';

const SeoContext = createContext();

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

async function fetchSeoParameters(url) {
  try {
    const response = await fetch(`${API_BASE_URL}/urls?url=${encodeURIComponent(url)}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });

    if (!response.ok) {
      return null;
    }

    const seoData = await response.json();
    return seoData;
  } catch (error) {
    return null;
  }
}

export function SeoProvider({ children }) {
  const [seoParams, setSeoParams] = useState({
    title: '',
    description: '',
    index: true,
  });

  useEffect(() => {
    let ignoreUpdate = false;

    const currentUrl = window.location.pathname;
    fetchSeoParameters(currentUrl).then(data => {
      if (data && !ignoreUpdate) {
        setSeoParams(prevState => ({
          ...prevState,
          title: prevState.title || data.meta_title || 'elalquilador - alquiler de maquinaria y herramientas profesionales',
          description: prevState.description || data.meta_description || '',
          index: prevState.index ?? (data.index && data.follow),
        }));
      }
    });

    return () => {
      ignoreUpdate = true;
    };
  }, []);

  return (
    <SeoContext.Provider value={{ seoParams, setSeoParams }}>
      <SeoManager />
      {children}
    </SeoContext.Provider>
  );
}

export function useSeo() {
  return useContext(SeoContext);
}

function SeoManager() {
  const { seoParams } = useSeo();
  const { title, description, index = true } = seoParams;

  useEffect(() => {
    if (title) {
      document.title = title;
    } else {
      document.title = 'Cargando...';
    }
  }, [title]);

  if (!title) {
    return null;
  }

  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta name="robots" content={index ? 'index, follow' : 'noindex, nofollow'} />
    </Helmet>
  );
}
