import React, { useState, useEffect } from "react";
import { useFilterContext } from "../../helpers/FilterContext";

const RenderCategories = ({ categories }) => {
  const [visibleLevels, setVisibleLevels] = useState({});
  const { state, dispatch } = useFilterContext();
  const [selectedCategories, setSelectedCategories] = useState([]);

  useEffect(() => {
    setSelectedCategories([...state.urlTestParams.filters?.categories_ids || []]);
  }, [state.urlTestParams.filters?.categories_ids]); 
  
  
  const toggleLevel = (path) => {
    setVisibleLevels((prev) => ({ ...prev, [path]: !prev[path] }));
  };

  const getAllChildrenIds = (category) => {
    if (!category.categories) return [];
    return category.categories.reduce(
      (acc, sub) => [...acc, sub.id, ...getAllChildrenIds(sub)],
      []
    );
  };

  const handleCheckBoxClick = (category) => {
  
    let updatedCategories = [...selectedCategories];
    const categoryId = category.id;
    const isCurrentlySelected = updatedCategories.includes(categoryId);
  
    if (isCurrentlySelected) {
      updatedCategories = updatedCategories.filter(
        (id) => id !== categoryId && !getAllChildrenIds(category).includes(id)
      );
    } else {
      updatedCategories.push(categoryId, ...getAllChildrenIds(category));
    }
  
    setSelectedCategories(updatedCategories);
    dispatch({ type: "UPDATE_FILTERS", payload: { filterId: "categories_ids", values: updatedCategories } });
  };
  

  const renderCategories = (categories, level = 0, path = "") => {
    if (!categories) return null;

    return categories.map((category, index) => {
      const newPath = `${path}/${index}`;
      const hasSubcategories = category.categories?.length > 0;
      const isChecked = selectedCategories.includes(category.id);

      return (
        <div key={category.id}>
          <div style={{ marginLeft: `${level * 20}px`, cursor: "pointer", display: "flex", alignItems: "center" }}>
            <div className="w-full" onClick={() => handleCheckBoxClick(category)}>
              <span className={`w-[15px] h-[15px] border border-primary inline-block mr-2 rounded-full checkbox-filter ${isChecked ? "checked" : ""}`}></span>
              <label>{category.description}</label>
            </div>
            {hasSubcategories && (
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" width="24" height="24" style={{ marginRight: "5px" }} onClick={() => toggleLevel(newPath)}>
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7"></path>
              </svg>
            )}
          </div>
          {visibleLevels[newPath] && hasSubcategories && <div>{renderCategories(category.categories, level + 1, newPath)}</div>}
        </div>
      );
    });
  };

  return <div>{renderCategories(categories)}</div>;
};

export default RenderCategories;
