import { useContext, useEffect, useState } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { PostsContext } from "../helpers/PostsContext";
import ImageInstagram from "../assets/icons/instagram.svg";
import ImageX from "../assets/icons/twitter.svg";
import ImageLink from "../assets/icons/linkedin.svg";
import ImageFacebook from "../assets/icons/facebook.svg";
import ShareLink from "../assets/icons/share.png";
import { DataEncoder } from "../utils/common";
import NoImageBlog from "../assets/images/Posts/no-image-blog.webp";
import { useNotification } from "../helpers/NotificationContext";
import BreadcrumbBlog from "../components/BreadcrumbBlog";
import PostForm from "../components/PostForm";
import { useSeo } from "../context/SeoContext";

const IMAGE_BASE_URL = process.env.REACT_APP_IMAGES_BASE_URL;

function Breadcrumb({ title }) {
  const navigate = useNavigate();

  const location = useLocation();
  const locationPathname = location.pathname.split("/")[1];

  const goBack = () => {
    navigate(`/${locationPathname}`);
  };

  return (
    <div className="container items-center gap-1 px-4 m-auto text-start md:flex lg:px-32">
      <button onClick={goBack} className="cursor-pointer">
        <div className="flex items-center gap-1 ">
          <svg
            className="fill-dark"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            width="24"
            height="24"
          >
            <path fill="none" d="M0 0h24v24H0z"></path>
            <path d="M20 11H7.83l5.59-5.59L12 4l-8 8 8 8 1.41-1.41L7.83 13H20v-2z"></path>
          </svg>
          <p className="text-sm text-dark lowercase">Volver a todos</p>
        </div>
      </button>
      <span>/</span>
      <p className="text-sm text-dark opacity-60 lowercase">{title}</p>
    </div>
  );
}

function PostDetail() {
  const { id } = useParams();
  const { postsNoPagination } = useContext(PostsContext);
  const [post, setPost] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const { setSeoParams } = useSeo();
  const encoder = new DataEncoder();
  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
  const arrayFilters = {
    with: ["media", "category", "state"],
  };
  const { showNotification } = useNotification();

  useEffect(() => {
    fetchPost();
  }, [id]);

  const [tableOfContents, setTableOfContents] = useState([]);

  useEffect(() => {
    if (post && post.content) { 
      const tempDiv = document.createElement("div");
      tempDiv.innerHTML = post.content;
  
      const headers = Array.from(tempDiv.querySelectorAll("h2, h3")).map((el) => ({
        id: el.textContent.toLowerCase().replace(/\s+/g, "-"),
        text: el.textContent,
        tag: el.tagName.toLowerCase(),
      }));
  
      setTableOfContents(headers);
    }
  }, [post]); 
  
  

  const handleCopyUrl = async () => {
    try {
      await navigator.clipboard.writeText(window.location.href);
      showNotification("URL copiada al portapapeles");
    } catch (err) {
      console.error("Error al copiar la URL:", err);
      showNotification("Error al copiar la URL");
    }
  };

  const fetchPost = async () => {
    setLoading(true);
    setError(null);

    const queryParams = encoder.encode(arrayFilters);
    const url = `${API_BASE_URL}/posts/${id}?${queryParams}`;

    try {
      const response = await fetch(encodeURI(url));
      if (!response.ok) {
        throw new Error("Error fetching post");
      }
      const data = await response.json();
      setPost(data);
      setSeoParams({
        title: data.meta_title || "Blog - ElAlquilador",
        description: data.meta_description || "Consulta nuestro blog de maquinaria y herramientas",
        index: data.index !== null ? data.index : true,
      });
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };


  const currentIndex = postsNoPagination.findIndex((p) => p.slug === id);

  const location = useLocation();
  const locationPathname = location.pathname.split("/")[1];

  const handleNextPost = () => {
    const nextPost = postsNoPagination[currentIndex + 1];
    if (nextPost) {
      navigate(`/${locationPathname}/${nextPost.slug}`);
    }
  };

  const handlePreviousPost = () => {
    const previousPost = postsNoPagination[currentIndex - 1];
    if (previousPost) {
      navigate(`/${locationPathname}/${previousPost.slug}`);
    }
  };

  if (loading) {
    return <div>Cargando...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  if (!post) {
    return <div>No se encontró el post</div>;
  }

  const processedContent = post?.content
  ? post.content.replace(/<h(2|3)>(.*?)<\/h(2|3)>/g, (match, level, text) => {
      const id = text
        .toLowerCase()
        .replace(/\s+/g, "-")  
        .replace(/[^\w\-]+/g, ""); 

      return `<h${level} id="${id}">${text}</h${level}>`;
    })
  : "";

  return (
    <section className="antialiased bg-light">
      <div id="intro" className="container px-4 pt-12 pb-12 m-auto lg:pt-24">
        <div className="relative h-[50vh] w-full overflow-hidden rounded-[2.5rem] bg-dark">
          {post.media && post.media.length > 0 ? (
            <img
              src={IMAGE_BASE_URL + post.media[0].path}
              alt={post.title}
              className="object-cover w-full h-full md:object-center"
            />
          ) : (
            <img
              src={NoImageBlog}
              className="object-cover w-full h-full md:object-center"
              alt="sin imagen"
            />
          )}
        </div>
        <div className="container flex items-center justify-between px-4 pt-12 m-auto lg:px-32">
          {post.category && (
            <div className="flex items-center px-4 py-1 text-sm text-left text-white lowercase border-2 rounded-full cursor-pointer border-primary bg-primary">
              {post.category.description}
            </div>
          )}
          <p className="text-[12px] text-[#B8B8B8]">
            {new Date(post.publication_date).toLocaleDateString()}
          </p>
        </div>
      </div>

      <div className="container px-4 py-12 m-auto lg:px-3 flex flex-col lg:flex-row gap-8">
        <div className="flex-1">
          <BreadcrumbBlog />
          <h1 className="font-bold text-left lowercase pb-9 h1">{post.title}</h1>
          <nav className="bg-gray-100 text-left p-4 mb-6 rounded-lg">
            <h2 className="text-lg font-bold">Índice</h2>
            <ul className="mt-2 space-y-1 ml-4">
              {tableOfContents.map(({ text }) => (
                <li key={text} className="cursor-pointer list-disc">
                  <button
                    className="text-secondary font-bold hover:underline"
                    onClick={() => {
                      const target = document.getElementById(text.toLowerCase().replace(/\s+/g, "-"));
                      if (target) {
                        target.scrollIntoView({ behavior: "smooth", block: "start" });
                      }
                    }}
                  >
                    {text}
                  </button>
                </li>
              ))}
            </ul>
          </nav>

          <div
            className="pb-2 space-y-6 text-base text-dark md:text-lg text-start custom-post"
            dangerouslySetInnerHTML={{ __html: processedContent }}
          />
          <div className="flex flex-wrap w-full gap-3 mt-10 text-3">
          {post.tags &&
            post.tags.map((tag) => (
              <div
                className="flex px-4 py-1 border rounded-full text-dark w-fit border-primary bg-primary "
                key={tag}
              >
                #{tag}
              </div>
            ))}
        </div>
        </div>
        
        <div className=" lg:block w-[400px] md:w-[350px]">
          <div className="sticky top-20">
            <PostForm />
          </div>
        </div>
      </div>
      <div className="container px-4 py-6 m-auto lg:px-32">
        <div className="flex items-center w-full border-t border-b border-black gap-9 border-opacity-30 py-9">
          <p className="text-xl font-bold text-dark">Compartir</p>
          <div className="flex flex-wrap space-x-6">
            <a
              href={`https://x.com/intent/tweet?url=${window.location.href}&text=${post.title}`}
              target="_blank"
              rel="noreferrer"
            >
              <img
                className="p-1 duration-300 ease-in-out rounded-full h-9 bg-primary opacity-80 hover:opacity-100"
                src={ImageX}
                alt="Twitter"
              />
            </a>
            <a
              href={`https://www.linkedin.com/sharing/share-offsite/?url=${window.location.href}`}
              target="_blank"
              rel="noreferrer"
            >
              <img
                className="p-1 duration-300 ease-in-out rounded-full h-9 bg-primary opacity-80 hover:opacity-100"
                src={ImageLink}
                alt="elgestionador LinkedIn"
              />
            </a>
            <a
              href={`https://www.facebook.com/sharer/sharer.php?u=${window.location.href}`}
              target="_blank"
              rel="noreferrer"
            >
              <img
                className="p-1 duration-300 ease-in-out rounded-full h-9 bg-primary opacity-80 hover:opacity-100"
                src={ImageFacebook}
                alt="Facebook"
              />
            </a>
            <button
              onClick={handleCopyUrl}
              className="p-1 duration-300 ease-in-out rounded-full bg-primary opacity-80 hover:opacity-100 w-[36px] h-[36px]"
            >
              <img className="max-w-[25px]" src={ShareLink} alt="Share link" />
            </button>
          </div>
        </div>

        {/* <div className="flex justify-between mt-4">
          {currentIndex > 0 && (
            <button
              className="flex items-center justify-end w-1/3 gap-4 p-6 truncate transition-all duration-300 ease-in-out bg-white rounded-full group hover:bg-primary hover:text-white"
              onClick={handlePreviousPost}
            >
              <svg
                className="flex-none fill-primary group-hover:fill-white"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                width="24"
                height="24"
              >
                <path fill="none" d="M0 0h24v24H0z"></path>
                <path d="M20 11H7.83l5.59-5.59L12 4l-8 8 8 8 1.41-1.41L7.83 13H20v-2z"></path>
              </svg>
              <span className="truncate lowercase">
                {postsNoPagination[currentIndex - 1].title}
              </span>
            </button>
          )}
          {currentIndex < postsNoPagination.length - 1 && (
            <button
              className={`group flex items-center justify-end gap-4 w-1/3 bg-white rounded-full p-6 truncate hover:bg-primary transition-all duration-300 ease-in-out hover:text-white ${
                currentIndex === 0 ? "ml-auto" : ""
              }`}
              onClick={handleNextPost}
            >
              <span className="truncate">
                {postsNoPagination[currentIndex + 1].title}
              </span>
              <svg
                className="flex-none fill-primary group-hover:fill-white"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                width="24"
                height="24"
              >
                <path fill="none" d="M0 0h24v24H0z"></path>
                <path d="M12 4l-1.41 1.41L15.17 10H4v2h11.17l-4.58 4.59L12 18l8-8z"></path>
              </svg>
            </button>
          )}
        </div> */}
      </div>
    </section>
  );
}

export default PostDetail;
