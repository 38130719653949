import React from "react";
import Wizard from "../components/Wizard";

const Home = () => {
  return (
    <div className="bg-light px-4 py-12 flex justify-center">
      <Wizard />
    </div>
  );
};

export default Home;
