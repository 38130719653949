import React, { createContext, useReducer, useContext, useState, useEffect } from 'react';

const FilterContext = createContext();

const filterReducer = (state, action) => {
  switch (action.type) {
    case 'PER_PAGE':
      return { ...state, urlTestParams: { ...state.urlTestParams, per_page: action.payload } };
    case 'SORT_BY':
      return { ...state, urlTestParams: { ...state.urlTestParams, sort: action.payload } };
    case 'SET_PAGE':
      return { ...state, urlTestParams: { ...state.urlTestParams, page: action.payload } };
    case 'SET_SEARCH':
      return { ...state, urlTestParams: { ...state.urlTestParams, search: action.payload, page: 1 } };
    case 'SET_FILTERS':
      return { ...state, filtersSelected: action.payload };
      case 'UPDATE_FILTERS': {
        let updatedFilters = {
          ...state.urlTestParams.filters,
          [action.payload.filterId]: action.payload.values
        };
      
        const filtersForUrl = { ...updatedFilters };
        delete filtersForUrl.with_offers;
        delete filtersForUrl.categories_ids; 
        delete filtersForUrl.brands_ids;

        const params = new URLSearchParams(window.location.search);
        if (Object.keys(filtersForUrl).length > 0) {
          params.set("filters", JSON.stringify(filtersForUrl));
        } else {
          params.delete("filters");
        }
      
        const newUrl = params.toString() ? `?${params.toString()}` : "";
        window.history.replaceState({}, "", newUrl);
      
        return {
          ...state,
          urlTestParams: { 
            ...state.urlTestParams, 
            filters: updatedFilters, 
            page: 1 
          }
        };
      }
      
      case "REMOVE_FILTER": {
        let newFilters = { ...state.urlTestParams.filters };
        if (action.payload === "categories_ids" || action.payload === "brands_ids") {
          newFilters[action.payload] = [];
        } else {
          delete newFilters[action.payload];
        }
        
      
        const filtersForUrl = { ...newFilters };
        delete filtersForUrl.with_offers;
        delete filtersForUrl.categories_ids;  
        delete filtersForUrl.brands_ids;
      
        const newParams = new URLSearchParams(window.location.search);
        if (Object.keys(filtersForUrl).length > 0) {
          newParams.set("filters", JSON.stringify(filtersForUrl));
        } else {
          newParams.delete("filters"); 
        }
      
        const newUrl = newParams.toString() ? `?${newParams.toString()}` : "";
        window.history.replaceState({}, "", newUrl);
      
        return { 
          ...state, 
          urlTestParams: { 
            ...state.urlTestParams, 
            filters: newFilters, 
            page: 1 
          } 
        };
      }
      
    case 'REMOVE_SORT':
      return { ...state, urlTestParams: { ...state.urlTestParams, sort: null } };
    case 'CHANGE_PAGE':
      return { ...state, urlTestParams: { ...state.urlTestParams, page: action.payload } };
    case 'UPDATE_CATEGORIE':
      return { ...state, categorie: action.payload };
    default:
      return state;
  }
};

const getFiltersFromUrl = () => {
  const params = new URLSearchParams(window.location.search);
  let filters = {};

  try {
    filters = params.get("filters") ? JSON.parse(params.get("filters")) : {};
  } catch (error) {
    console.error("Error al parsear filtros desde la URL:", error);
  }

  return { ...filters, with_offers: true };
};

const FilterProvider = ({ children }) => {
  const [perPage, setPerPage] = useState(21);
  const [search, setSearch] = useState('');
  const initialState = {
    urlTestParams: {
      'with': ['image', 'products_offers_published.junior:id,zip'],
      'filters': getFiltersFromUrl(),
      'page': 2,
      'per_page': perPage
    },
    categorie: 'Todos los productos',
    filtersSelected: {}
  };

  const [state, dispatch] = useReducer(filterReducer, initialState);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const urlFilters = params.get("filters") ? JSON.parse(params.get("filters")) : {};

    if (JSON.stringify(urlFilters) !== JSON.stringify(state.urlTestParams.filters)) {
      dispatch({ type: "SET_FILTERS", payload: urlFilters });
    }
  }, []);

  const updateFilters = (filterId, values) => {
    dispatch({ type: 'UPDATE_FILTERS', payload: { filterId, values: [values] } });
  };

  return (
    <FilterContext.Provider value={{ state, dispatch, updateFilters }}>
      {children}
    </FilterContext.Provider>
  );
};

const useFilterContext = () => {
  return useContext(FilterContext);
};

export { FilterProvider, useFilterContext };
