import React, { useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const categorySteps = {
  "elevacion de personas": 3,
  "construccion": 6,
  "movimiento de cargas": 7,
  "jardineria": 9,
  "medios auxiliares": 10,
  "limpieza y tratamiento de superficies": 11,
  "electricidad, iluminacion y energia": 12,
  "pintura y climatizacion": 13,
  "carpinteria y corte": 14,
  "maquinaria agricola y forestal": 15,
  "fontaneria": 16,
  "accesorios y consumibles": 17,
};

const categorySubIds = {
  "construccion": 134,
  "jardineria": 132,
  "medios auxiliares": 135,
  "limpieza y tratamiento de superficies": 136,
  "electricidad, iluminacion y energia": 137,
  "pintura y climatizacion": 140,
  "carpinteria y corte": 141,
  "maquinaria agricola y forestal": 133,
  "fontaneria": 138,
  "accesorios y consumibles": 139,
};

const Wizard = () => {
  const navigate = useNavigate();
  const [step, setStep] = useState(1);
  const [filters, setFilters] = useState({});
  const [categories, setCategories] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const maxHeight = 43.15;

  const fetchCategories = async () => {
    const url = `${API_BASE_URL}/categories?with[]=categories.categories.categories&filters[level]=1`;
    try {
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error(`Error en la API: ${response.status}`);
      }
      const data = await response.json();
      if (data?.data) setCategories(data.data);
    } catch (error) {
      console.error("Error fetching categories:", error);
    }
  };
  useEffect(() => {
    fetchCategories();
  }, []);

  const fetchSubCategories = async (categoryId, parentCategoryName) => {
    try {
        const response = await fetch(
            `https://elalquilador-erp.dev-lab.es/api/categories?filters[level]=2&filters[category_id]=${categoryId}`
        );
        const data = await response.json();
        const categoriesWithOtherOption = [
            "fontaneria",
            "maquinaria agricola y forestal",
            "pintura y climatizacion",
            "electricidad, iluminacion y energia"
        ];

        let updatedSubCategories = [...data.data];
        if (categoriesWithOtherOption.includes(parentCategoryName)) {
            updatedSubCategories.push({ id: "otros", description: "Otros" });
        }

        setSubCategories(updatedSubCategories);
    } catch (error) {
        console.error("Error fetching subcategories:", error);
        setSubCategories([]);
    }
};



  const handleAnswer = (answer) => {
    setFilters((prev) => {
      if (answer === "sí") {
        return { ...prev, use_type: "Herramienta" };
      } else if (answer === "no") {
        return { ...prev, use_type: "Maquinaria" };
      }
      return prev;
    });
  
    setStep((prev) => prev + 1);
  };
  

  const normalizeText = (text) => {
    return text
      .normalize("NFD") 
      .replace(/[\u0300-\u036f]/g, "") 
      .toLowerCase();
  };
  
  const handleCategorySelect = async (category) => {
    const categoryName = normalizeText(category.description);
  
    const allCategoryIds = getAllSubcategoryIds(category);
    setFilters((prev) => ({
      ...prev,
      categories_ids: allCategoryIds,
    }));
  
    setSelectedCategory(categoryName);
    setStep(categorySteps[categoryName] || 5);
  
    if (categorySubIds[categoryName]) {
      await fetchSubCategories(categorySubIds[categoryName], categoryName);
    }
};

  

const handleSubCategorySelect = (subCategory) => {
  setFilters((prev) => {
      let updatedCategories = [];
      if (subCategory.id === "otros") {
          return {
              ...prev,
              categories_ids: [categorySubIds[selectedCategory]],
          };
      }
      updatedCategories.push(subCategory.id);
      return {
          ...prev,
          categories_ids: updatedCategories,
      };
  });
  setStep(5);
};



  const handleMaxLoadSelect = (range) => {
    const loadRanges = {
      "menos de 2 toneladas": ["14", 136, 2000],
      "entre 2 y 5 toneladas": ["14", 2000, 5000],
      "más de 5 toneladas": ["14", 5000, 10000],
    };

    setFilters((prev) => ({
      ...prev,
      max_load: loadRanges[range],
    }));
    setStep(8);
  };

  const handleHeightSelect = (range) => {
    const heightRanges = {
      "menos de 10": ["12", 3, 10],
      "entre 10 y 20": ["12", 10, 20],
      "más de 20": ["12", 20, maxHeight],
    };

    setFilters((prev) => ({
      ...prev,
      height: heightRanges[range],
    }));
    setStep(4);
  };

  const handlePropulsionSelect = (option) => {
    const propulsionOptions = {
      exterior: ["9", "gasolina", "diesel"],
      interior: ["9", "eléctrica", "manual"],
    };

    setFilters((prev) => ({
      ...prev,
      propulsion: propulsionOptions[option],
    }));
    setStep(5);
  };

  const handleZipCodeSubmit = (event) => {
    event.preventDefault();
    let zip = event.target.zipcode.value.trim();
    zip = parseInt(zip, 10);

    if (isNaN(zip)) {
        return;
    }

    setFilters((prev) => {
        const updatedFilters = {
            ...prev,
            zip_distance: [zip, 100],
        };

        buildURL(updatedFilters);
        return updatedFilters;
    });
};
const buildURL = (updatedFilters) => {
    const params = new URLSearchParams();

    if (Object.keys(updatedFilters).length > 0) {
        params.set("filters", JSON.stringify(updatedFilters));
    }
    window.location.href = `/alquiler${params.toString() ? `?${params.toString()}` : ""}`;
};


  const getAllSubcategoryIds = (category) => {
    let ids = [category.id];
    if (category.categories) {
      category.categories.forEach((sub) => {
        ids = ids.concat(getAllSubcategoryIds(sub));
      });
    }
    return ids;
  };

  const QuestionStep = ({ question, options, onSelect, isLoading = false, loadingText = "Cargando..." }) => (
    <div>
      <p className="h5 font-bold text-dark mt-4">{question}</p>
      <div className="flex flex-wrap justify-between mt-3 gap-2">
      {isLoading ? (
        <p>{loadingText}</p>
      ) : (
        options.map((option) => (
          <button className="text-sm bg-primary button flex items-center justify-center text-white rounded-full px-6 py-2 border-2 border-primary hover:bg-transparent hover:border-primary hover:text-primary transition-all ease-in-out duration-500 w-auto mt-2" key={option.id} onClick={() => onSelect(option)}>
            {option.description}
          </button>
        ))
      )}
      </div>
    </div>
  );
  

  return (
    <div className="wizard-container text-center bg-white w-full max-w-lg md:max-w-3xl rounded-2xl py-12 lg:py-20 px-6 sm:px-12  flex flex-col md:flex-row items-center justify-center">
  {step === 1 && (
    <div>
      <h2 className="h2 text-primary">¡Bienvenido!</h2>
      <p className="h4 font-bold text-dar mt-4">Te ayudo a encontrar lo que buscas con sólo 6 preguntas.</p>
      <p className="h5 font-bold text-dark mt-4">¿Lo que buscas cabe en una furgoneta?</p>
      <div className="flex justify-between mt-3">
      {["sí", "no", "no estoy seguro"].map((answer) => (
        <button className="text-sm bg-primary button flex items-center justify-center text-white rounded-full px-9 py-2 border-2 border-primary hover:bg-transparent hover:border-primary hover:text-primary transition-all ease-in-out duration-500 w-full mt-2 mr-2" key={answer} onClick={() => handleAnswer(answer)}>
          {answer.charAt(0).toUpperCase() + answer.slice(1)}
        </button>
      ))}
      </div>
    </div>
  )}

  {step === 2 && (
    <QuestionStep
      question="¿Qué trabajo vas a realizar?"
      options={categories}
      onSelect={handleCategorySelect}
      isLoading={categories.length === 0}
      loadingText="Cargando categorías..."
    />
  )}

  {step === 3 && selectedCategory === "elevacion de personas" && (
    <QuestionStep
      question="¿Qué altura necesitas alcanzar?"
      options={[
        { id: "menos de 10", description: "Menos de 10 metros" },
        { id: "entre 10 y 20", description: "Entre 10 y 20 metros" },
        { id: "más de 20", description: "Más de 20 metros" },
      ]}
      onSelect={(option) => handleHeightSelect(option.id)}
    />
  )}

  {step === 4 && selectedCategory === "elevacion de personas" && (
    <QuestionStep
      question="¿El trabajo es en exterior o interior?"
      options={[
        { id: "exterior", description: "Exterior: Diesel o gasolina" },
        { id: "interior", description: "Interior: Eléctrica o manual" },
        { id: "ambos", description: "Ambos" },
        { id: "no estoy seguro", description: "No estoy seguro" },
      ]}
      onSelect={(option) => handlePropulsionSelect(option.id)}
    />
  )}

  {step === 7 && selectedCategory === "movimiento de cargas" && (
    <QuestionStep
      question="¿Cuál es el peso de la carga que necesitas mover?"
      options={[
        { id: "menos de 2 toneladas", description: "2 toneladas o menos" },
        { id: "entre 2 y 5 toneladas", description: "Entre 2,1 y 5 toneladas" },
        { id: "más de 5 toneladas", description: "Más de 5 toneladas" },
      ]}
      onSelect={(option) => handleMaxLoadSelect(option.id)}
    />
  )}

  {step === 8 && selectedCategory === "movimiento de cargas" && (
    <QuestionStep
      question="¿La necesitas para el exterior o el interior?"
      options={[
        { id: "exterior", description: "Exterior: Diesel o gasolina" },
        { id: "interior", description: "Interior: Eléctrica o manual" },
        { id: "ambos", description: "Ambos" },
        { id: "no estoy seguro", description: "No estoy seguro" },
      ]}
      onSelect={(option) => handlePropulsionSelect(option.id)}
    />
  )}

  {[
    { step: 6, category: "construccion", question: "Detalla el tipo de trabajo" },
    { step: 9, category: "jardineria", question: "¿Qué tipo de tarea de jardinería?" },
    { step: 10, category: "medios auxiliares", question: "Detalla el tipo de producto" },
    { step: 11, category: "limpieza y tratamiento de superficies", question: "¿Qué tipo de tarea?" },
    { step: 12, category: "electricidad, iluminacion y energia", question: "¿Qué tipo de solución necesitas?" },
    { step: 13, category: "pintura y climatizacion", question: "¿Qué tarea vas a llevar a cabo?" },
    { step: 14, category: "carpinteria y corte", question: "¿Qué tarea vas a llevar a cabo?" },
    { step: 15, category: "maquinaria agricola y forestal", question: "¿Qué tarea vas a llevar a cabo?" },
    { step: 16, category: "fontaneria", question: "¿Qué necesitas?" },
    { step: 17, category: "accesorios y consumibles", question: "¿Qué necesitas?" },
  ].map(({ step: s, category, question }) =>
    step === s && selectedCategory === category ? (
      <QuestionStep
        key={s}
        question={question}
        options={subCategories}
        onSelect={handleSubCategorySelect}
        isLoading={subCategories.length === 0}
        loadingText="Cargando subcategorías..."
      />
    ) : null
  )}

  {step === 5 && (
    <div>
      <h3>¿Para qué código postal la necesitas?</h3>
      <div className="w-full md:w-1/2 flex justify-between mt-9 lg:mt-4">
      <form onSubmit={handleZipCodeSubmit}>
        <input className="w-[80vw] m-auto text-center md:w-80 font-bold appearance-none button text-dark rounded-full px-2 py-6 border-2 border-dark focus:outline-none placeholder-primary placeholder:font-bold focus:placeholder-gray placeholder:text-center" type="text" name="zipcode" placeholder="Ingresa tu código postal" required />
        <button className="w-[80vw] md:w-80 mt-2 m-auto bg-primary button flex items-center justify-center text-white rounded-full px-2 py-6 border-2 border-primary hover:bg-transparent hover:text-primary transition-all ease-in-out duration-150" type="submit">Buscar</button>
      </form>
      </div>
    </div>
  )}
</div>

  );
};

export default Wizard;
